<template>
  <transition name="dialog">
    <div
        v-if="showDialog"
        id="dialog-bg"
        @touchmove.prevent
        @scroll.prevent>
      <div id="dialog">
        <el-row :gutter="20">
          <el-col :span="4" class="close" @click.native="close">取消</el-col>
          <el-col :span="16">
            <span style="text-align: center; font-family: 'Arial Black'">
              {{ title }}
            </span>
          </el-col>
          <el-col :span="4" class="confirm" @click.native="confirm">确定</el-col>
          <el-divider></el-divider>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="arrow">吃水</div>
          </el-col>
          <el-col :span="12">
            <van-field v-model="number1" type="number" input-align="center" placeholder="吃水始"/>
          </el-col>
          <el-col :span="12">
            <van-field v-model="number2" type="number" input-align="center" placeholder="吃水终"/>
          </el-col>
        </el-row>
        <el-divider class="divider"></el-divider>
        <div style="margin-top: 40px">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="arrow">泊位数量</div>
            </el-col>
            <el-col :span="24">
              <van-field v-model="number3" type="number" input-align="center" placeholder="请填写数量"/>
<!--              <div v-for="(item,index) in this.baseInfo" :key="index" class="model">-->
<!--                <div>-->
<!--                  <van-col span="23">-->
<!--                    <van-cell-group>-->
<!--                      <van-field-->
<!--                          readonly-->
<!--                          clickable-->
<!--                          :placeholder="item.label"-->
<!--                          :value="shipType"-->
<!--                          input-align="right"-->
<!--                          @click="onClickField(index)"-->
<!--                      />-->
<!--                      <van-popup v-model="item.showPicker" round position="bottom">-->
<!--                        <van-picker-->
<!--                            show-toolbar-->
<!--                            :columns="item.columns"-->
<!--                            @cancel="item.showPicker = false"-->
<!--                            @confirm="onConfirm"-->
<!--                            default-index="0"-->
<!--                        />-->
<!--                      </van-popup>-->
<!--                    </van-cell-group>-->
<!--                  </van-col>-->
<!--                </div>-->
<!--              </div>-->
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

  </transition>
</template>

<script>
export default {
  name: "index",
  props: {
    showDialog: Boolean,
    title: String,
    message: String
  },
  data() {
    return {
      checkList: [],
      number1: '',
      number2: '',
      number3: '',
      enterpriseTypeTree: [
        {lable: '货方', value: 'HF', id: 'HF', btn: true},
        {lable: '船方', value: 'CF', id: 'CF', btn: false},
        {lable: '港方', value: 'GF', id: 'GF', btn: false},
        {lable: '其他', value: 'QT', id: 'QT', btn: false}
      ],
      enterpriseNatureTree: [
        {lable: '生产型', value: 'HF01', id: 'HF01', btn: false},
        {lable: '贸易型', value: 'HF02', id: 'HF02', btn: false},
        {lable: '同行业', value: 'HF03', id: 'HF03', btn: false},
      ],
      enterpriseLevelTree: [
        {lable: 'A阶', value: 'A', id: 'A', btn: false},
        {lable: 'B阶', value: 'B', id: 'B', btn: false},
        {lable: 'C阶', value: 'C', id: 'C', btn: false},
        {lable: 'D阶', value: 'D', id: 'D', btn: false},
      ],
      shipType: '',
      baseInfo: [
        {
          index: 1,
          titel: '船型',
          label: '请选择',
          showPicker: false,
          columns: [
            '刘小恺',
            '董阔',
            '纪红昊',
            '冷琳',
            '李佳宇',
            '路志国'
          ]
        }
      ]
    };
  },

  methods: {
    close: function () {
      this.$emit("closeDialog");
    },
    confirm: function () {
      this.checkList = []
      this.checkList.push(this.number1)
      this.checkList.push(this.number2)
      this.checkList.push(this.number3)
      this.$emit('sendmsg',this.checkList)
    },
    selectEnterpriseTypeTree(val) {
      switch (val) {
        case 'HF':
          this.enterpriseNatureTree = [
            {lable: '生产型', value: 'HF01', id: 'HF01', btn: false},
            {lable: '贸易型', value: 'HF02', id: 'HF02', btn: false},
            {lable: '同行业', value: 'HF03', id: 'HF03', btn: false},
          ]
          break
        case 'CF':
          this.enterpriseNatureTree = [
            {lable: '船方', value: 'CF01', id: 'CF01', btn: false},
            {lable: '船东', value: 'CF02', id: 'CF02', btn: false},
            {lable: '船东代理', value: 'CF03', id: 'CF03', btn: false},
          ]
          break
        case 'GF':
          this.enterpriseNatureTree = [
            {lable: '港口', value: 'GF01', id: 'GF01', btn: false},
            {lable: '港方', value: 'GF02', id: 'GF02', btn: false},
            {lable: '港口代理', value: 'GF03', id: 'GF03', btn: false},
          ]
          break
        case 'QT':
          this.enterpriseNatureTree = [
            {lable: '其他', value: 'QT01', id: 'QT01', btn: false},
            {lable: '其他', value: 'QT02', id: 'QT02', btn: false},
            {lable: '其他', value: 'QT03', id: 'QT03', btn: false},
          ]
          break
      }
      this.resetBtn(val);
    },
    selectEnterpriseNatureTree(val) {
      for (var i = 0; i < this.enterpriseNatureTree.length; i++) {
        this.enterpriseNatureTree[i].btn = false;
      }
      this.enterpriseNatureTree.find(item => item.value === val).btn = true;
    },
    selectEnterpriseLevelTree(val) {
      this.enterpriseLevelTree.find(item => item.value === val).btn = !this.enterpriseLevelTree.find(item => item.value === val).btn;
    },
    resetBtn(val) {
      for (var i = 0; i < this.enterpriseTypeTree.length; i++) {
        this.enterpriseTypeTree[i].btn = false;
      }
      this.enterpriseTypeTree.find(item => item.value === val).btn = true;
    },
    onClickField(index) {
      this.baseInfo[index].showPicker = true
      this.index = index;
    },
    onConfirm(picker, value) {
      console.log(picker, value);
      this.selValue = picker;
      this.baseInfo[this.index].label = picker;
      this.baseInfo[this.index].showPicker = false;
      this.shipType = picker;
    },
  },

  watch: {}
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

#dialog-bg {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;
}

#dialog {
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  background-color: #FFFFFF;
  width: 100vw;
  padding: 15px;
  padding-bottom: 10%;
}

/* 适配电脑等大屏幕 */
@media (min-width: 750px) {
  #dialog {
    width: 500px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.dialog-button {
  width: 100%;
  background-color: #6367D5;
  border-width: 0;
  border-radius: 360px;
  padding: 10px;
  outline: none;
  color: white;
}

.dialog-button:focus {
  outline: none;
}

.dialog-button:active {
  background-color: #585dbe;
  border-color: #585dbe;
}

.dialog-enter-active, .dialog-leave-active {
  transition: all .5s;
}

.dialog-enter, .dialog-leave-to {
  opacity: 0;
  transform: translateY(300px);
}

.close {
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 9px 0;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  line-height: 1.57;
  color: #999aaa;
  text-align: left
}

.confirm {
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 9px 0;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  line-height: 1.57;
  color: rgba(0, 0, 0, .85);
  text-align: right
}

.arrow {
  text-align: left;
  margin-top: -25px;
}

.el-divider--horizontal {
  display: block;
  transform: scale(1);
  height: 1px;
  width: 100%;
  margin: 40px 0;
}

.children {
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 9px 0;
  font-size: 15px;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  line-height: 1.57;
  color: rgba(0, 0, 0, .85);
}

.children #txt {
  margin-right: 10px
}

.divider {
  margin: 10px 0;
}

/*@keyframes dialog-in {*/
/*  0% {*/
/*    transform: scale(0);*/
/*  }*/
/*  50% {*/
/*    transform: scale(1.5);*/
/*  }*/
/*  100% {*/
/*    transform: scale(1);*/
/*  }*/
/*}*/
</style>