<template>
  <div>
    <!-- 搜索框-->
    <van-row style="background-color: white">
      <van-col span="5" style="margin-top: 20px;padding-left: 10px">
        <i @click="reverseAll()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
           :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">全部</i>
        <i @click="reverseAll()" :class="`el-icon-${isAll ? 'arrow-down' : 'arrow-up'}`"
           :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
      </van-col>
      <van-search v-model="portName" show-action @cancel="onCancel()" @search="onRefresh()" placeholder="请输入港口名称"/>
    </van-row>
    <van-row>
      <van-col span="11" :style="{fontSize:'15px'}">当前查询数据条数：{{ list.length }}条</van-col>
    </van-row>
    <!-- 列表-->
    <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
      <div v-if="list.length<=0">
        <van-empty image="error" description="没有数据"/>
      </div>
      <div v-else>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model="loading"
              loading-text="加载中"
              :finished="finished"
              finished-text="没有更多了"
              @load="nextPage"
              style="margin-bottom: 45px"
          >
            <Port-info-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <port-info-select
        title="多条件选择"
        message="多条件选择"
        :showDialog="isShowDialog"
        @sendmsg='getMsg'
        @closeDialog="isShowDialog=false, isAll= !isAll"></port-info-select>
    <Add v-if="isAdd" to="/BulkCrmsPortInfoDetailAdd/" :param=param></Add>
    <!-- 底部导航栏-->
    <crm-tab-bar/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import PortInfoCell from '@/components/PortInfoCell/index'
import Add from '@/components/Add';
import PortInfoSelect from "@/components/PortInfoSelect/index"
import {Loading} from 'vant';

export default {
  name: "BulkCrmsPortInfoList",
  components: {CrmTabBar, PortInfoSelect, Add, PortInfoCell, Loading},
  data() {
    return {
      isAdd: false,
      isAll: false,
      isShowDialog: false,
      colors: '',
      list: [],
      portName: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      currentPage: 1, // 当前页码
      pageSize: 5, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      param: '',
      number1: '',
      number2: '',
      number3: '',
    }
  },
  watch: {
    //监听公司名头
    'portName': function () {
      this.list = [];
      this.onRefresh();
    },
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - this.$refs.fresh.getBoundingClientRect().top - 50
    if (this.$route.params.id === 'no') {
      this.$message.warning("该客户还没有对应港口数据！");
    } else {
      this.onRefresh();
    }
    this.param = this.$route.params.id
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    reverseAll() {
      this.isAll = !this.isAll
      this.isShowDialog = true;
    },
    getMsg(data) {
      this.isAll = !this.isAll
      this.isShowDialog = false;
      console.log(data)
      this.number1 = data[0] != 'null' ? data[0] : 0
      this.number2 = data[1] != 'null' ? data[1] : 0
      this.number3 = data[2] != 'null' ? data[2] : 0
      console.log(this.number1 + "," + this.number2 + "," + this.number3 + ",")
    },
    getData() {
      this.loading = true
      this.$ajax.post('/api/ajax/bulkCrmPortinfo/getBulkCrmPortinfotwoList.json',
          {
            current: this.currentPage,
            portOtherid: this.$route.params.id,
            size: this.pageSize,
            portName: this.portName,
            portId: '',
            createdPeople: '',
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          if(res.data.length==0){
            this.$message.warning("还没有对应港口数据！");
          }else {
            if (res.data[0].createdPeople == this.$store.getters.userId) {
              this.isAdd = true
            }
            // 如果下拉刷新，那么数据清空重新获取第一页
            if (this.refreshing) {
              this.list = []
              this.refreshing = false
            }
            console.log(res.data)
            res.data.forEach(item => {
              this.list.push(item)
            })
            this.listRow = this.list.length
            // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
            if (res.data.length < this.pageSize - 1) {
              this.finished = true
            } else {
              this.finished = false
            }
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].no = i + 1
              this.list[i].detail = '查看详情'
              this.list[i].to = '/BulkCrmsPortInfoDetailList/' + this.list[i].portId
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    onCancel() {
      this.portName = ''
      this.onRefresh()
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
  }
}
</script>

<style scoped>

</style>
